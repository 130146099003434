<template>
  <div>
    <div class="head">修改绑定手机</div>
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="新手机">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="当前密码">
        <el-input
          v-model="form.loginPassword"
          show-password
          @blur="checkPersonCode"
        ></el-input>
        <el-link type="primary" :underline="false">
          <router-link to="change_code"> 忘记密码</router-link>
        </el-link>
      </el-form-item>
      <el-form-item label="图片验证码">
        <el-input
          id="code"
          class="code"
          v-model="code"
          maxlength="14"
        ></el-input>
        <div class="login-code graphcs-code" @click="refreshCode">
          <!--验证码组件-->
          <el-tooltip
            effect="light"
            :hide-after="1100"
            content="看不清?点击切换"
            placement="right"
          >
            <s-identify :identifyCode="identifyCode"></s-identify>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item label="短信验证码">
        <el-input v-model="form.phonec" maxlength="14"></el-input>
        <el-button type="primary" @click="getVerifyCode" v-show="messageCode"
          >获取短信验证码</el-button
        >
        <el-button type="info" v-show="!messageCode"
          >(<span>{{ count_down }}</span
          >s)后重新发送</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="cancle">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import SIdentify from "@/components/sidentify";
import { QUERY, UPDATE, DELETE, INSERT } from "@/services/dao.js";
import axios from "axios";
export default {
  components: { SIdentify },
  data() {
    return {
      form: {
        id: "",
        phone: "",
        loginPassword: "",
        phonec: "",
      },
      identifyCodes: "1234567890",
      identifyCode: "",
      code: "", //text框输入的验证码
      messageCode: true, // 验证码板块儿
      count_down: 60, // 倒计时
      first_phone: "", // 接收短信验证码的手机号
      flag: Boolean, //输入当前密码正确 则为true
      flag_1: false, // 短信验证码正确则为true
    };
  },
  created() {
    this.init();
    this.refreshCode();
  },

  methods: {
    async init() {
      let info = await QUERY(
        "post",
        "",
        'PCUsers(where: {id: {_eq: "' +
          this.$store.state.userinfo.id +
          '"}}) {id      phone  }'
      );
      this.form = info.data.PCUsers[0];
    },
    // 获取短信验证码倒计时
    getMessageCode() {
      // console.log("点击")
      this.messageCode = false;
      let time = 59;
      let t = setInterval(() => {
        this.count_down = time;
        time--;
        if (time < 0) {
          this.count_down = 60;
          clearInterval(t);
          this.messageCode = true;
        }
      }, 1000);
    },
    // 获取手机验证码
    async getVerifyCode() {
      // 验证是否为合法
      let reg_phone = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!reg_phone.test(this.form.phone)) {
        this.$message.warning("请填写正确的手机号!");
        return;
      }
      // 查询该手机号是否被注册
      // 根据用户输入的手机号 查找数据库 该手机号是否未被注册
      let search = await QUERY(
        "post",
        "",
        'PCUsers(where:{phone:{_eq:"' + this.form.phone + '"}}) {id}'
      );
      // console.log("search_affected_rows_loginName",search);
      // console.log("查找数据库 该手机号是否未被注册: ",search.data.PCUsers.length>0)
      if (search.data.PCUsers.length > 0) {
        this.$message.warning("该手机号已被注册!");
        return;
      }
      // 验证通过则发送手机验证码
      this.getMessageCode(); // 验证通过则进入倒计时
      // 请求后台发送短信验证码
      await axios
        .post(
          this.api.LoginURL.concat("/user/sendVCode"),
          {
            phone: this.form.phone,
          },
          {
            headers: {
              // 'Access-Control-Allow-Origin':'*',  //解决cors头问题
              // 'Access-Control-Allow-Credentials':'true', //解决session问题
              "Content-Type": "application/json", //将表单数据传递转化为form-data类型
            },
          }
        )
        .then((res) => {
          // console.log("拿到后台短信验证码cb: ",res);
          // 给 message_code赋值
          if (res.data.success) {
            // 发送验证码成功才存入手机号
            this.first_phone = this.form.phone;
          } else {
            this.$message.warning(res.data.respMsg);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkPersonCode() {
      let info = await QUERY(
        "post",
        "",
        'PCUsers(where: {id: {_eq: "' +
          this.$store.state.userinfo.id +
          '"},loginPassword: {_eq: "' +
          this.$md5(this.form.loginPassword) +
          '"}}) {id      name  }'
      );
      // console.log(info.data.PCUsers.length)
      if (info.data.PCUsers.length !== 1) {
        this.$message({
          message: "当前密码错误!",
          type: "error",
          duration: "1000",
        });
        this.flag = false;
        return false;
      } else {
        return true;
      }
    },
    async onSubmit() {
      // console.log(this.form.field);
      // console.log( this.form.loginPassword);
      // console.log("this.form.loginPassword === undefined --->",this.form.loginPassword === undefined)
      // console.log("this.form.loginPassword !== undefined --->",this.form.loginPassword !== undefined)
      // 验证密码是否正确
      if (this.form.loginPassword === undefined) {
        this.$message.warning("请输入当前密码!");
        return;
      }
      if (!this.flag) {
        this.$message.warning("当前密码错误!");
        return;
      }
      // else{
      //   if(!this.checkPersonCode()){
      //     return;
      //   }
      // }
      if (this.code == "") {
        this.$message({
          message: "请输入图形验证码",
          type: "warning",
          duration: "1000",
        });
        return;
      }
      if (this.identifyCode !== this.code) {
        this.code = "";
        this.identifyCode = "";
        this.$message({
          message: "请输入正确的图形验证码",
          type: "warning",
          duration: "1000",
        });
        return;
      }

      // 验证短信验证码的正确性
      //
      if (this.form.phone == "" || this.form.phonec == "") {
        this.$message.warning("请完善信息!");
        return;
      }
      // console.log("register->phone");
      await axios
        .post(this.api.LoginURL.concat("/user/register"), {
          phone: this.form.phone,

          vcode: this.form.phonec,
        })
        .then((res) => {
          // console.log("用户填的验证码发到后台cb: ",res);
          if (!res.data.success) {
            this.$message.warning(res.data.respMsg);
            // console.log("66666",!res.data.success)
            return;
          }
          this.flag_1 = res.data.success;
        })
        .catch((err) => {
          console.log(err);
        });
      // 是否更改手机号
      if (this.first_phone != this.form.phone) {
        this.$message.warning("请勿更改手机号,请重新获取验证码!");
        return;
      }
      // 如果手机号与短信验证码匹配
      if (this.flag_1) {
        let data = await UPDATE(
          "POST",
          "",
          'update_User(_set:{phone: "' +
            this.form.phone +
            '"}, where: {id: {_eq:' +
            this.$store.state.userinfo.id +
            "}}) {  affected_rows }"
        );
        if (data.data.update_User.affected_rows > 0) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: "1000",
          });
          this.$router.push({ name: "Basicinformation" });
        } else {
          this.$message({
            message: "修改失败",
            type: "warning",
            duration: "1000",
          });
        }
      }
    },
    handleChange(value) {
      console.log(value);
    },
    //验证码
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    // 取消返回上一级
    cancle() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
};
</script>
<style scoped>
.head {
  border-bottom: 1px solid #b5b5b5;
  line-height: 50px;
  margin-bottom: 40px;
  margin-left: 15px;
  font-size: 15px;
  font-weight: 800;
}
.label {
  margin-left: -50px;
}
.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 50%;
  margin-right: 10px;
}
.code {
  width: 124px;
  height: 31px;
  float: left;
  /* border: 1px solid rgba(186, 186, 186, 1); */
}
.login-code {
  cursor: pointer;
}
.graphcs-code {
  width: 51%;
}
</style>
